// make sure to change background-color in index.html to ensure website will have the same background-color on load
$background-color: rgb(12, 12, 12); 
$primary-text-color: #C8C8C8;
$secondary-text-color: #9e9e9e;
$dark-text-color: #8b8b8b;
$shadow-color: rgba(0,0,0,0.67);
$error-color: #D80460;

$MIN_DESKTOP_WIDTH: 700;
$min-desktop-width: $MIN_DESKTOP_WIDTH + "px";
$max-mobile-width: $MIN_DESKTOP_WIDTH - 1 + "px";
$min-tablet-width: 768px;
$max-tablet-width: 1024px;

// exported variables to be imported in JS
:export {
  MIN_DESKTOP_WIDTH: $MIN_DESKTOP_WIDTH;
  DARK_TEXT_COLOR: $dark-text-color;
}