.Loader {
  font-size: 10pt;
  width: 11em;

  &__ZLogo {
    width: 100%;
    position: relative;

    &--path-1 {
      // stroke-dasharray: 490; 
      stroke-dasharray: 125 125;
    }

    &--path-2 {
      // stroke-dasharray: 425;
      stroke-dasharray: 115 115;
    }
  }
}