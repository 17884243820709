@import "../../Utils/variables.scss";
@import "../../Utils/mixins.scss";

.Error404 {
  height: 100vh;
  font-size: 11pt;
  position: relative;
  overflow: hidden;

  @include desktop-styles {
    font-size: 12pt;
  }

  &__wrapper {
    @include center;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  &__art {
    height: min-content;
    position: relative;
    z-index: 0;

    @include desktop-styles {
      left: -0.3em;
    }
  }

  &__abstract {
    width: 99vw;
    position: relative;
    z-index: 0;

    &--path {
      stroke-dasharray: 405 405;
      stroke-dashoffset: 0;
    }

    @include desktop-styles {
      width: 30em;
    }
  }
  
  &__glow {
    width: 114vw;
    position: absolute;
    top: -8.5vw;
    left: -10vw;
    z-index: -2;

    @include desktop-styles {
      width: 34em;
      top: -2.3em;
      left: -2.5em;
    }
  }

  &__messageWrapper {
    width: 100%;
    height: min-content;
    display: grid;
    grid-template-rows: min-content min-content;
    position: absolute;

    @include mobile-styles {
      top: 35%;
    }
  }
  
  &__message {
    font-weight: bold;
    font-style: italic;
    text-align: center;
    text-shadow: 7px 3px 20px $shadow-color;

    &--statusCode {
      justify-self: start;
      font-size: 53vw;
      position: relative;
      left: -5.5vw;
      
      @include mobile-styles {
        line-height: 35vw;
      }

      @include desktop-styles {
        font-size: 15.3em;
        top: -1.4em;
        left: 0.02em;
      }
    }

    &--0 {
      position: relative;
      z-index: -1;
    }

    &--text {
      font-size: 10vw;
      position: relative;
      left: -4.5vw;

      @include desktop-styles {
        font-size: 3em;
        top: -8em;
        left: 0.1em;
      }
    }
  }

  &__subMessage {
    color: $secondary-text-color;
    font-style: italic;
    text-align: center;
  }

  &__homeButton {
    width: 10em;
    @include gradient-border(#131313, #EA1A39, #3C1DF2);
    
    font-size: 12pt;
    font-style: italic;
    text-align: center;
    color: $primary-text-color;
    padding: 0.6em 0.5em;
    margin: 1.5em auto;
    position: relative;
    z-index: 1;
  }
}