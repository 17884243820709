@import "../../Utils/variables.scss";
@import "../../Utils/mixins.scss";

.Home {
  font-size: 14pt;
  height: 100vh;
  background-image: radial-gradient(rgb(46, 46, 46), $background-color);

  @include mobile-styles {
    font-size: 11pt;
  }
  
  &__wrapper {
    @include center;
    width: min-content;
    display: flex;
    flex-flow: row nowrap;

    @include mobile-styles {
      flex-flow: column nowrap;
    }
  }

  &__Loader {
    align-self: center;
    justify-self: end;

    @include mobile-styles {
      justify-self: center;
      margin-bottom: 1.5em;
      width: 7em;
    }
  }

  &__main {
    align-self: center;
    padding-left: 2em;

    @include mobile-styles {
      padding: 0.5em;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    }
  }

  &__header {
    font-size: 2.5em;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 0.15em;
    color: $primary-text-color;
  }

  &__message {
    width: 20em;
    white-space: pre-line;
    line-height: 1.4em;
    color: $secondary-text-color;

    @include mobile-styles {
      width: 100%;
      text-align: center;
    }
  }

  &__email {
    text-decoration: underline;
  }

  &__socials {
    width: 8.1em;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.8em;
  }

  &__social {
    stroke-width: 2;
    transition: 0.65s;
    
    &--GitHub {
      height: 1.86em;
      stroke-dasharray: 395 395;
      stroke-dashoffset: 0;

      &:hover {
        stroke-dashoffset: -790;
      }
    }
    
    &--LinkedIn {
      height: 1.82em;
      stroke-dasharray: 265 265;
      stroke-dashoffset: 0;

      &:hover {
        stroke-dashoffset: -530;
      }
    }

    &--Medium {
      height: 1.63em;
      stroke-dasharray: 485 485;
      stroke-dashoffset: 0;
      
      &:hover {
        stroke-dashoffset: -970;
      }
    }
    
  }

  &__break {
   @include mobile-styles {
     display: none;
   }
  }
}
